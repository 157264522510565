import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 线索类型
const clueTypes = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/clue/types', params)
}

// 线索公安局
const clueoOrgs = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/clue/orgs', params)
}

// 线索上报
const clueReport = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/clue/report', params)
}

// 举报记录
const clueRecords = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/clue/records', params)
}
export default {
	clueTypes,
	clueoOrgs,
	clueReport,
	clueRecords
}
