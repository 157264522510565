<template>
	<div class="ClueType">
		<w-navTab titleText="反映类型"></w-navTab>
		<div class="container">
			<div class="head-title">
				反映类型
			</div>
			<div class="head-form">
				<div class="head-form-item" v-for="(item,index) in typeList" @click="onTypeList(item,index)">
					<div :class="['item-left',{'active-color' : active == index}]">{{item.name}}</div>
					<div class="item-right">
						+{{item.love_value}}爱心值<van-icon style="margin-left: 12px;" name="success" color="#3377FF" size="20" v-if="active == index" />
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="footer-button" @click="toClueReport">确定</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClueReport from '@/api/clueReport';
import Vant from '@/vendor/vant';
import Event from '@/store/event';
export default {
	name: 'ClueType',
	data() {
		return {
			active: 0,
			typeList:[],
			typeId: '',
		};
	},
	created() {
		ClueReport.clueTypes()
			.then(result=>{
				this.typeList = result.data
			},error=>{
				Vant.Toast(error.msg);
			})
	},
	methods: {
		onTypeList(item,index){
			this.active = index
			this.typeId = item.type_id
		},
		toClueReport(){
			this.$router.back()
			Event.$emit('onClueType', this.typeList[this.active]);
		}
	}
};
</script>

<style scoped lang="less">
.ClueType {
	background: #F5F6F8;
	min-height: 100vh;
	position: relative;
	.container{
		padding: 10px;
		box-sizing: border-box;
		.head-title{
			font-weight: bold;
			font-size: 17px;
			line-height: 20px;
			color: #1C1D1D;
			margin-bottom: 12px;
			margin-top: 2px;
		}
		.head-form {
			.head-form-item{
				background: #FFF;
				border-radius: 6px;
				padding: 13px 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;
				.item-left{
					font-size: 15px;
					line-height: 22px;
					color: #1C1D1D;
					&.active-color{
						color: #3377FF;
					}
				}
				.item-right{
					font-size: 12px;
					line-height: 17px;
					color: #777B81;
					display: flex;
					align-items: center;
					min-width: 90px;
				}
			}
		}
		
		.footer{
			position: fixed;
			bottom: 12px;
			left: 0;
			padding: 0 10px;
			width: 100%;
			box-sizing: border-box;
			.footer-button{
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				color: #FFF;
				padding: 11px 0;
				background: linear-gradient(92.48deg, #3377FF 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 4px;
			}
		}
	}
}
</style>
